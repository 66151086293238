.input {
    display: inline-block;
    position: relative;
    width: 100%;
    vertical-align: middle;

    &__label {
        font-size: 10px;
        position: absolute;
        left: 20px;
        opacity: 1;
        transform: translateY(3px);
        transition: opacity 0.2s ease-out, transform 0.2s ease-out;
        color: #000;

        &--hidden {
            opacity: 0;
            transform: translateY(0);
        }
    }

    &__elem {
        display: block;
        background-color: transparent;
        border: 1px solid;
        border-radius: 4px;
        font-family: $font-stack-base;
        font-size: 22px;
        font-weight: $font--normal;
        height: 48px;
        line-height: 1;
        padding: 7px 20px 10px;
        text-align: left;
        transition: border 0.2s ease-out;
        width: 100%;
        border-color: #000;
        color: #000;

        &:focus,
        &:hover {
            outline: 0;
            border-color: #000;
        }

        &:focus {
            background-color: #000;
        }

        @include placeholder() {
            font-size: 16px;
            font-weight: $font--light;
            color: #000;
        }

        .input--value & {
            padding-top: 15px;
            padding-bottom: 7px;
        }

        .input--error & {
            color: #f00;
            background-color: #f00;
            border-color: #f00;
        }

        .input--password & {
            padding-right: 40px;
        }
    }
}
