// Font families
// @type List
$font-stack-base: helvetica, arial sans-serif !default;
$font-stack-extra: helvetica, arial sans-serif !default;

// Font-sizes
// @type Length
$font--page: 16px !default;

// Font weights
// @type Length
$font--ultra-light: 100 !default;
$font--light: 200 !default; // or thin
$font--book: 300 !default; // or demi
$font--normal: 400 !default; // or regular
$font--medium: 500 !default;
$font--semi-bold: 600 !default; // or demi-bold
$font--bold: 700 !default;
$font--heavy: 800 !default; // or black/extra-bold
$font--fat: 900 !default; // or extra-black/poster/ultra-black

// Default line-height
// @type Length
$lh--base: 1.42857143; // 20/14

// Base grid width
// @type Length
$width--min: 320px !default;

// Transitions
// @type Property Values
$transition--default: all 0.2s ease-out;
