.page {
    font-family: $font-stack-base;
    font-size: $font--page;
    font-weight: $font--normal;
    height: 100%;
    line-height: $lh--base;
    margin: 0;
    min-width: $width--min;
    padding: 0;

    // Remove dotted outline on input or button text in Firefox
    & *::-moz-focus-inner {
        border: none;
    }

    // Remove clear cross and reveal eye on input in IE11
    & *::-ms-clear,
    & *::-ms-reveal {
        display: none;
    }
}
